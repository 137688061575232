#header.scrolled {
    background-color: white;
    box-shadow: 0 0 7px -4px black;
}
@keyframes shine {
    0% {
        background-position: -200%;
    }
    100% {
        background-position: 200%;
    }
}

.heroSubTopic {
    position: relative;
    display: inline-block;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgb(255, 255, 255) 50%, rgba(255,255,255,0) 100%);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: shine 5s infinite linear;
    /*animation-iteration-count: 1;*/
}

.heroSubTopic::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #cecece, rgba(255, 255, 255, 0.64));
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    z-index: -1;
}


@keyframes colorChange {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.heroMainTopic {
    animation: colorChange 10s linear infinite;
    background-size: 200% 200%;
}

.logos-slide {
    animation: 35s logo-animation infinite linear;
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

@keyframes logo-animation {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}



@media screen and (max-width: 875px) {
    .company-name-explain {
        width: 320px;
    }

    .home-page-free-session-sub-section{
        width: 200px!important;
    }
}


@media screen and (max-width: 780px) {
    .client-and-project-counts-wrapper{
        gap: 50px;
    }

    .client-and-project-counts{
        flex-direction: column!important;
        align-items: center;
        justify-content: center;
        gap: 25px;
        padding-top: 25px;
        padding-bottom: 25px;
        bottom: 0;
    }

    .client-and-project-counts-lines{
        height: 1px;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .company-name-explain-section{
        gap: 50px;
    }

    .company-name-explain {
        width: 100%;
    }
}

@media screen and (max-width: 730px) {
    #home-page-free-session-section{
        bottom: 18px;
    }

    .home-page-free-session-sub-section{
        width: 90% !important;
        height: 200px !important;
    }

    .services{
        width: 90% !important;
    }
}

@media screen and (max-width: 480px) {

    #home-page-project-section > div:nth-child(2) > div > div > img,
    #home-page-project-section > div:nth-child(2) > div > div {
        border-radius: 10px;
    }

    #contact-info-section > h1 {
        line-height: 36px;
        font-size: 32px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .contact-btn, .view-btn {
        width: 125px;
        height: 50px;
        font-size: 18px;
        padding: 5px;
    }

    .heroMainTopic {
        font-size: 38px;
        line-height: 40px;
    }

    .heroSubTopic {
        font-size: 36px;
        line-height: 40px;
    }

    #home-page-customer-feedback-section > div:first-child > p {
        padding-left: 1.25em;
        padding-right: 1.25em;
    }

    .portfolio-card {
        height: max-content;
        padding: 0 10px 25px 10px
    }

    .portfolio-card > h2 {
        font-size: 24px;
        line-height: unset;
        margin-bottom: 20px;
    }

    .portfolio-card > p {
        font-size: 16px;
        line-height: unset;
    }

    .portfolio-nav-bar > div {
        width: max-content;
        font-size: 14px;
    }
}

@media screen and (max-width: 425px) {
    .home-page-free-session-sub-section{
        height: 220px !important;
    }
    #home-page-project-section > div:nth-child(2) > div > div {
        width: 340px;
        height: 220px;
    }

    #contact-info-section > h1 {
        padding-right: 25px;
        padding-left: 25px;
    }

    .heroMainTopic {
        font-size: 36px;
        line-height: 40px;
    }

    .heroSubTopic {
        font-size: 34px;
        line-height: 38px;
    }

    .portfolio-card {
        width: 320px;
    }
}

@media screen and (max-width: 375px) {
    .services{
        height: 280px !important;
    }
    #portfolio-hero-section {
        height: unset;
    }

    #home-page-project-section > div:nth-child(2) > div > div {
        width: 300px;
        height: 200px;
    }

    #contact-info-section > p {
        padding-left: 30px;
        padding-right: 30px;
    }

    #contact-info-section > h1 {
        line-height: 30px;
        font-size: 28px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .heroMainTopic {
        font-size: 34px;
        line-height: 38px;
    }

    .heroSubTopic {
        font-size: 32px;
        line-height: 36px;
    }


}

@media screen and (max-width: 320px) {
    #home-page-about-section {
        height: 750px;
    }

    #home-page-about-section > div > p {
        font-size: 14px;
        padding: 0;
    }

    #home-page-about-section > div > div > div {
        margin-bottom: 50px;
    }

    #home-page-about-section > div > div > div > h1 {
        font-size: 48px;
    }

    #home-page-about-section > div > div > div > p {
        font-size: 16px;
    }

    #home-page-about-section > div > div {
        flex-direction: column;
    }

    #contact-info-section > h1 {
        font-size: 24px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .heroMainTopic {
        font-size: 32px;
    }

    .heroSubTopic {
        font-size: 28px;
        line-height: 30px;
    }

    .portfolio-card {
        width: 290px;
    }
}
