@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #F9F9F9;
}

.lato-thin {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.lato-thin-italic {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.lato-light {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.lato-light-italic {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.lato-regular-italic {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.lato-bold-italic {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.lato-black-italic {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: italic;
}
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
}
.roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
}
