#header {
    justify-content: space-between;
    background-color: #292929;
    position: fixed;
    color: white;
    top: 0;
    width: 100%;
    height: 76px;
    z-index: 50;
    transition: background-color 0.3s ease;
}

#header.scrolled {
    color: white;
    background-color: #292929;
    box-shadow: 0 0 7px -4px black;
}
